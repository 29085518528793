import React from "react"
import Seo from "../../components/Core/Seo"
import Layout from "../../components/Layout/Layout"
import colors from "../../styles/colors"
import ForgotPassword from "../../components/AccountContent/ForgotPassword"

const ForgotPasswordPage = ({ location }) => {
  const path = location.pathname
  return (
    <Layout dark bg={colors.white}>
      <Seo
        title="Forgot Password Page"
        description="In Good Company Forgot Password Page"
        fullTitle="Forgot Password Page"
        path={path}
      />
      <ForgotPassword />
    </Layout>
  )
}

export default ForgotPasswordPage
