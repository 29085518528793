import React, { useState, useEffect } from "react"
import styled from "styled-components"
import PageWrapper from "./PageWrapper"
import { AccountButtonDark, AccountButtonLightLink, H2 } from "./AccountStyles"
import AccountInputField from "./AccountInputField"
import { useUserContext } from "../../context/UserContext"
import {
  validateEmail,
  validateFormFields,
} from "../../services/account/popupFormHelper"
import { sendRecoverEmail, getResetUrl } from "../../services/user/user"
import ErrorPopup from "./ErrorPopup"
import { Link } from "gatsby"
import fonts from "../../styles/fonts"

const Info = styled.p`
  font-size: 0.889rem;
  line-height: 1.5;
  color: #1c1a17;
  margin: 0;
  margin-bottom: 2.5rem;
  ${fonts.openSansRegular};
`

const ButtonWrapper = styled.div`
  margin: 1.5rem 0;
`
const SubmitBtn = styled(AccountButtonDark)`
    pointer-events: ${props => props.disabled ? "none" : "all"};
    cursor: ${props => props.disabled ? "normal" : "pointer"};
`

const invalidEmailError = "Please enter a valid email address"

const ForgotPassword = () => {
  const [formData, setFormData] = useState({ email: "" })
  const [fieldErrors, setFieldErrors] = useState({ email: "" })
  const [formError, setFormError] = useState(null)
  const [isSending, setIsSending] = useState(false)
  const [emailSent, setEmailSent] = useState(false)

  useEffect(() => {
    return () => {
      setEmailSent(false)
    }
  }, [])

  useEffect(() => {
    let errorTimeOut
    if (formError) {
      errorTimeOut = setTimeout(() => {
        setFormError(null)
      }, 3000)
    }
    return () => {
      clearTimeout(errorTimeOut)
    }
  }, [formError])

  const handleChange = (name, value) => {
    let updatedFormData = formData

    updatedFormData[name] = value
    setFormData(updatedFormData)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError(null)
    setFieldErrors({
      email: "",
    })
    let hasError = validateFormFields(formData, fieldErrors, setFieldErrors)

    if (fieldErrors.email.trim().length > 0) {
      let validEmail = validateEmail(formData.email)
      if (!validEmail) {
        hasError = true
        const emailError = {email: invalidEmailError}
        setFieldErrors(emailError)
        return
      }
    }
    if (hasError) {
      return
    }

    try {
      setIsSending(true)
      let resetURL = getResetUrl(formData.email)
      await sendRecoverEmail(formData.email, resetURL)
      setEmailSent(true)
      setIsSending(false)
    } catch (error) {
      console.error(error)
      setFormError("An error occurred, please try again.")
      setIsSending(false)
    }
  }

  return (
    <PageWrapper>
      <H2>Forgot your password?</H2>
      <Info>Enter your email address and we’ll send you an email on how to reset your password.<br></br> If you don't recieve the email, please contact orders@eatigc.com</Info>
      <form onSubmit={handleSubmit} novalidate>
        <AccountInputField
          data={formData}
          name="email"
          placeholder="Email"
          handleChange={handleChange}
          type="email"
          error={fieldErrors.email}
          label="E-mail"
        />
        <ButtonWrapper>
          <SubmitBtn type="submit" disabled={emailSent || isSending}>
          {isSending ? "Sending..." : emailSent ? `Email sent` : `Send Email`}
          </SubmitBtn>
        </ButtonWrapper>
        <AccountButtonLightLink to="/account/login">
          Back to log in
        </AccountButtonLightLink>
      </form>
      {formError && (
        <ErrorPopup>
          <span>{formError}</span>
        </ErrorPopup>
      )}
    </PageWrapper>
  )
}

export default ForgotPassword
